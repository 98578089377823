import {
    createContext,
    type FC,
    type ReactNode,
    useState,
    useContext,
    useCallback,
    useEffect,
} from 'react';

type Context = {
    layers: string[];
    addToLayer: (id: string) => void;
    removeFromLayer: (id: string) => void;
};

export const ModalLayerContext = createContext<Context>({
    layers: [],
    addToLayer: () => {},
    removeFromLayer: () => {},
});

/**
 * Wrap your modal in this provider to turn on stacking behavior. Meaning if your modal opens another
 * modal, the first modal will be hidden. Closing the second modal will reveal the first modal again.
 */
export const ModalLayerManagerProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [layers, setLayers] = useState<Context['layers']>([]);

    const addToLayer = useCallback<Context['addToLayer']>(id => {
        setLayers(layerList => {
            if (layerList.includes(id)) {
                return layerList;
            }
            return [...layerList, id];
        });
    }, []);

    const removeFromLayer = useCallback<Context['removeFromLayer']>(id => {
        setLayers(layerList => {
            if (layerList.includes(id)) {
                const index = layerList.indexOf(id);
                return layerList.slice(0, index);
            }
            return layerList;
        });
    }, []);

    return (
        <ModalLayerContext.Provider value={{ addToLayer, removeFromLayer, layers }}>
            {children}
        </ModalLayerContext.Provider>
    );
};

export const useModalLayerManagerContext = (): Context => {
    return useContext(ModalLayerContext);
};

export const useModalVisibilityHandler = ({ modalId }: { modalId: string }): [boolean] => {
    const modalLayerManager = useModalLayerManagerContext();
    const layers = modalLayerManager.layers;
    const [shouldHideModal, setModalHidden] = useState(false);

    useEffect(() => {
        /**
         * If modal isn't the first one in the layer list - hide it.
         */
        const lastModalId = layers.slice(-1)[0];
        if (lastModalId && lastModalId !== modalId) {
            setModalHidden(true);
        } else {
            setModalHidden(false);
        }
    }, [modalId, layers]);

    return [shouldHideModal];
};
