import { useState, useCallback, useRef, useEffect, type RefObject } from 'react';
import { useDebouncedCallback } from 'dibs-react-hooks/exports/useDebouncedCallback';
import { type DraggableModalProps } from './index';

const initialModalOffset = 18;

export const useDraggable = ({
    isDraggable,
    dragHandleRef,
    presetPositionX,
    presetPositionY,
    setPosition,
    modalRef,
}: DraggableModalProps & {
    modalRef: RefObject<HTMLDivElement>;
}): void => {
    const [isDragging, setIsDragging] = useState(false);
    const position = useRef({ x: 0, y: 0 });
    const draggableElement = modalRef?.current;
    const dragHandleElement = dragHandleRef?.current;

    useEffect(() => {
        if (draggableElement && (presetPositionX || presetPositionY)) {
            position.current = {
                x: presetPositionX || 0,
                y: presetPositionY || 0,
            };
        }
    }, [draggableElement, presetPositionX, presetPositionY]);

    const onMouseUp = useCallback((e: MouseEvent): void => {
        setIsDragging(false);
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const onMouseDown = useCallback((): void => {
        setIsDragging(true);
    }, []);

    const [debouncedSetPosition] = useDebouncedCallback(() => {
        if (typeof setPosition === 'function') {
            setPosition(position.current.x, position.current.y);
        }
    }, 150);

    const onMouseMove = useCallback(
        (e: MouseEvent): void => {
            if (!draggableElement) {
                return;
            }

            const { innerWidth, innerHeight } = window;
            const elementWidth = draggableElement.offsetWidth;
            const elementHeight = draggableElement.offsetHeight;

            position.current = {
                x: position.current.x + e.movementX,
                y: position.current.y + e.movementY,
            };

            const constrainedX = Math.min(
                Math.max(position.current.x, -(innerWidth - elementWidth - initialModalOffset)),
                initialModalOffset
            );

            const constrainedY = Math.min(
                Math.max(position.current.y, -initialModalOffset),
                innerHeight - elementHeight - initialModalOffset
            );

            requestAnimationFrame(() => {
                draggableElement.style.transform = `translate(${constrainedX}px, ${constrainedY}px)`;
            });

            position.current = {
                x: constrainedX,
                y: constrainedY,
            };

            debouncedSetPosition();
        },
        [draggableElement, debouncedSetPosition]
    );

    useEffect(() => {
        if (!isDraggable || !dragHandleElement) {
            return () => {};
        }

        dragHandleElement.addEventListener('mousedown', onMouseDown);
        dragHandleElement.style.cursor = 'all-scroll';

        return () => {
            dragHandleElement.removeEventListener('mousedown', onMouseDown);
        };
    }, [isDraggable, dragHandleElement, onMouseDown]);

    useEffect(() => {
        if (!isDraggable || !draggableElement) {
            return () => {};
        }

        if (isDragging) {
            draggableElement.style.transition = 'none';
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        } else {
            draggableElement.style.transition = '';
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };
    }, [isDragging, draggableElement, isDraggable, onMouseMove, onMouseUp]);
};
